import { useFetch } from '../../composable/useFetch.js';

const state = () => ({
  types: []
})

const getters = {
  getName: (state) => (configType) => {
    return state.types.find(type => type.value === configType)?.text
  }
}

const mutations = {
  setTypes(state, types) {
    state.types = [...types]
  },
}

const actions = {
  async loadTypes(context) {
    const data = await useFetch('/configs')
    const mappedData = Object.keys(data).map(element => ({
      text: data[element].name, value: element
    }))
    context.commit('setTypes', mappedData)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
