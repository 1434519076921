import { useFetch } from '../../composable/useFetch.js';

const state = () => ({
  error: null,
  isLoading: false,
  data: [],
  pagination: null,
})

const mutations = {
  setData(state, data) {
    state.data = [...data]
  },
  setIsLoading(state, status) {
    state.isLoading = status
  },
  setError(state, error) {
    state.error = error
  },
  setPagination(state, pagination) {
    state.pagination = pagination
  }
}

const actions = {
  async loadData(context, { page } = { page: 1 }) {
    context.commit('setIsLoading', true)
    const { data, ...pagination } = await useFetch(`/systems?page=${page}`)
    context.commit('setPagination', pagination.meta)
    context.commit('setData', data)
    context.commit('setIsLoading', false)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
