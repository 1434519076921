import { createStore } from 'vuex'
import systems from './modules/systems.js'
import screenSlider from './modules/screenSlider.js'
import systemTypes from './modules/systemTypes.js'
import currentSystem from './modules/currentSystem.js'
import currentModule from './modules/currentModule.js'
import plantsModule from './modules/plantsModule'
import clients from './modules/clients.js'
import partners from './modules/partners.js'

export default createStore({
  modules: {
    systems,
    screenSlider,
    systemTypes,
    currentSystem,
    currentModule,
    plantsModule,
    clients,
    partners,
  }
})
