import { useFetch } from '../../composable/useFetch.js';
import dayjs from 'dayjs';

const state = () => ({
  module: null,
})

const getters = {
  daysUntilHarvest: (state) => {
    if(!state.module?.current_cultivation_cycle) return '-'
    const now = dayjs()
    const startsAt = dayjs(state.module.current_cultivation_cycle.starts_at)
    const duration = dayjs.duration(now.diff(startsAt))
    const durationInDays = duration.asDays()
    const cultivationLength = state.module.current_cultivation_cycle.plant.cultivation_length
    return (cultivationLength - durationInDays).toFixed(2)
  },
  formattedPlantedOn: (state) => {
    if (!state.module?.current_cultivation_cycle?.starts_at) return '-'
    return dayjs(state.module.current_cultivation_cycle.starts_at).format('MMMM D, YYYY')
  }
}

const mutations = {
  setModule(state, module) {
    state.module = module
  },
}

const actions = {
  async loadModule(context, { serialNumber, moduleNumber }) {
    if (!serialNumber || !moduleNumber) context.commit('setModule', null)
    else {
      const data = await useFetch(`/systems/${serialNumber}/modules/${moduleNumber}`)
      context.commit('setModule', data)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
