import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import Notifications from '@kyvg/vue3-notification'

dayjs.extend(duration)
dayjs.extend(isToday)

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    createApp(App).use(router).use(store).use(Notifications).mount('#vue-app')
  })
}
