import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/Home/Home.vue'),
    meta: {
      breadcrumb: [
        { name: 'Home', current: true }
      ]
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '../pages/Clients/Clients.vue'),
    meta: {
      breadcrumb: [
        { name: 'Clients', current: true }
      ]
    }
  },
  {
    path: '/systems',
    name: 'Systems',
    component: () => import(/* webpackChunkName: "systems" */ '../pages/Systems/Systems.vue'),
    meta: {
      breadcrumb: [
        { name: 'Systems', current: true }
      ]
    }
  },
  {
    path: '/systems/:serial_number',
    name: 'system:serial_number',
    props: route => ({ id: route.params.serial_number }),
    component: () => import('../pages/Systems/CurrentSystem.vue'),
    meta: {
      breadcrumb: [
        { name: 'Systems', href: '/systems', current: false },
        { name: 'System dashboard', current: true }
      ]
    }
  },
  {
    path: '/systems/:serial_number/:module_number',
    name: 'system:serial_number:module_number',
    component: () => import('../pages/Systems/CurrentModule.vue'),
    meta: {
      breadcrumb: [
        { name: 'Systems', href: '/systems', current: false },
        { name: 'System dashboard', href: route => `/systems/${route.params.serial_number}`, current: false },
        { name: 'Module dashboard', current: true }
      ]
    }
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import(/* webpackChunkName: "admins" */ '../pages/Admins/Admins.vue'),
    meta: {
      breadcrumb: [
        { name: 'Admins', current: true },
      ]
    }
  },
  {
    path: '/plants',
    name: 'PlantsCatalogue',
    component: () => import(/* webpackChunkName: "plants" */ '../pages/PlantsCatalogue/PlantsCatalogue.vue'),
    meta: {
      breadcrumb: [
        { name: 'Plants Catalogue', current: true },
      ]
    }
  },
  {
    path: '/plants/:name',
    name: 'CurrentPlant',
    component: () => import('../pages/PlantsCatalogue/CurrentPlant.vue'),
    meta: {
      breadcrumb: [
        { name: 'Plants Catalogue', href: '/plants', current: false },
        { name: route => route.params.name.split('-').join(' '), current: true },
      ]
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import(/* webpackChunkName: "support" */ '../pages/Support/Support.vue'),
    meta: {
      breadcrumb: [
        { name: 'Support', current: true },
      ]
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '../pages/Settings/Settings.vue'),
    meta: {
      breadcrumb: [
        { name: 'Settings', current: true },
      ]
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
