<template>
  <nav
    class="flex pl-4 xl:pl-0"
    aria-label="Breadcrumb"
  >
    <ol class="flex items-center space-x-4">
      <li>
        <div>
          <router-link
            to="/"
            class="text-gray-400 hover:text-gray-500"
          >
            <HomeIcon
              class="flex-shrink-0 h-5 w-5"
              aria-hidden="true"
            />
            <span class="sr-only">Home</span>
          </router-link>
        </div>
      </li>
      <li
        v-for="page in pages"
        :key="page.name"
      >
        <div class="flex items-center">
          <SlashIcon class="flex-shrink-0 h-5 w-5 text-gray-300" />
          <component
            :is="page.tag"
            :to="page.href"
            class="ml-4 text-sm font-medium text-gray-500 capitalize"
            :class="page.class"
            :aria-current="page.ariaCurrent"
          >
            {{ page.name }}
          </component>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
import { HomeIcon } from '@heroicons/vue/solid'
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import SlashIcon from '../../../images/slash.svg'

export default defineComponent({
  components: {
    HomeIcon,
    SlashIcon
  },
  setup() {
    const route = useRoute()
    const pages = computed(() => route.meta.breadcrumb?.map(page => {
      const name = typeof page.name === 'function' ? page.name(route) : page.name
      const href = typeof page.href === 'function' ? page.href(route) : page.href
      if (page.current) return { tag: 'span', ariaCurrent: 'page', class: 'select-none', href, name }
      return { tag: 'router-link', ariaCurrent: undefined, class: 'hover:text-gray-700', href, name }
    }))

    return {
      pages
    }
  },
})
</script>
