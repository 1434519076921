const useFetch = async (endpoint, method = 'GET', body, baseURL = '/api/v1') => {
  const url = `${window.location.origin}${baseURL}${endpoint}`
  try {
    const response = await fetch(url, {
      method,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      },
      ...(body ? { body: JSON.stringify(body) } : {})
    })
    return response.status !== 204 && await response.json()
  } catch (error) {
    console.error('fetch function error', error)
    error.customMessage = 'There are problems with API server, please wait.'
    return error
  }
}

export { useFetch }
