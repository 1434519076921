<template>
  <notifications
    width="100%"
    class="max-w-sm"
    position="top right"
  >
    <template #body="{ item }">
      <div class="p-3">
        <div
          class="w-full bg-white shadow-md rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <component
                  :is="iconComponent(item.type)"
                  class="h-6 w-6"
                  :class="iconClasses(item.type)"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ item.title }}
                </p>
                <p
                  v-if="item.text"
                  class="mt-1 text-sm text-gray-500"
                >
                  {{ item.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotificationsGroup',
  components: { CheckCircleIcon, ExclamationCircleIcon, XIcon },
  setup() {
    const iconClasses = (type) => type === 'error' ? 'text-red-400' : 'text-green-400'
    const iconComponent = (type) => type === 'error' ? 'ExclamationCircleIcon' : 'CheckCircleIcon'

    return { iconClasses, iconComponent }
  }
})
</script>
