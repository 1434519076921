<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot
      as="template"
      :show="modelValue"
    >
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 xl:hidden"
        @close="$emit('update:modelValue', false)"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-custom-green">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="$emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 flex items-center px-4">
              <HexagroIcon class="h-8 w-auto mx-auto" />
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto text-white">
              <SidebarNavigation />
            </div>
          </div>
        </TransitionChild>
        <div
          class="flex-shrink-0 w-14"
          aria-hidden="true"
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-custom-green text-white xl:flex xl:flex-shrink-0">
      <div class="flex flex-col w-64">
        <div class="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <HexagroIcon class="h-8 w-auto mx-auto" />
          </div>
          <div class="mt-5 flex-1 flex flex-col">
            <SidebarNavigation />
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-0 flex-1 overflow-hidden bg-white">
      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <div class="w-full">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import HexagroIcon from '../../../images/menu_logo.svg';
import {
  XIcon
} from '@heroicons/vue/outline';
import SidebarNavigation from '../SidebarNavigation/SidebarNavigation.vue';

export default {
  name: 'AppLayout',
  components: {
    SidebarNavigation,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    HexagroIcon,
    XIcon
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
}
</script>
