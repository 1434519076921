const state = () => ({
  isOpened: false,
  activeSliderId: null,
  props: {}
})

const getters = {
  isSliderOpened: (state) => (id) => {
    return state.isOpened && state.activeSliderId === id
  }
}

const mutations = {
  setIsOpened(state, status) {
    state.isOpened = status
  },
  setActiveSliderId(state, id) {
    state.activeSliderId = id
  },
  setProps(state, props) {
    state.props = props
  }
}

const actions = {
  open(context, { id, props = {} }) {
    context.commit('setIsOpened', true)
    context.commit('setActiveSliderId', id)
    context.commit('setProps', props)
  },
  close(context, { id }) {
    if (context.state.activeSliderId !== id) return
    context.commit('setIsOpened', false)
    context.commit('setProps', {})
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
