<template>
  <AppLayout v-model="isSideNavigationShown">
    <div class="flex divide-x-2 divide-gray-200 divide-solid xl:divide-x-0 p-4">
      <button
        @click="showSideNavigation"
        class="xl:hidden mr-4"
      >
        <MenuIcon class="text-gray-400 hover:text-gray-500 w-8" />
      </button>
      <Breadcrumb />
    </div>
    <router-view />
  </AppLayout>
  <notifications-group />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import AppLayout from './components/AppLayout/AppLayout.vue'
import Breadcrumb from './components/Breadcrumb/Breadcrumb.vue';
import NotificationsGroup from './components/NotificationsGroup/NotificationsGroup.vue';
import { MenuIcon } from '@heroicons/vue/solid'

export default defineComponent({
  name: 'App',
  components: {
    NotificationsGroup,
    Breadcrumb,
    AppLayout,
    MenuIcon
  },
  setup() {
    const store = useStore()
    const route = useRoute()

    const isSideNavigationShown = ref(false)

    const closeSideNavigation = () => isSideNavigationShown.value = false
    const showSideNavigation = () => isSideNavigationShown.value = true

    watch(() => route.fullPath, closeSideNavigation)

    onMounted(async () => {
      await store.dispatch('systemTypes/loadTypes')
    })

    return { isSideNavigationShown, showSideNavigation }
  }
})
</script>
