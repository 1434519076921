import { useFetch } from '../../composable/useFetch.js';

const state = () => ({
  system: null,
  contract: null,
  client: null,
  partner: null,
  modules: null,
})

const mutations = {
  setSystem(state, system) {
    state.system = system
  },
  setContract(state, contract) {
    state.contract = contract
  },
  setClient(state, client) {
    state.client = client
  },
  setPartner(state, partner) {
    state.partner = partner
  },
  setModules(state, modules) {
    state.modules = modules
  }
}

const actions = {
  async loadSystem(context, serialNumber) {
    if (!serialNumber) {
      context.commit('setSystem', null)
      context.commit('setContract', null)
      return
    }
    const system = await useFetch(`/systems/${serialNumber}`)
    context.commit('setSystem', system)
    context.commit('setPartner', system?.partner)
    context.commit('setContract', system?.contract)
    const modules = await useFetch(`/systems/${serialNumber}/modules`)
    context.commit('setModules', modules.data)
  },
  async loadClient(context, clientId) {
    if (!clientId) {
      context.commit('setClient', null)
      return
    }
    const data = await useFetch(`/clients/${clientId}`)
    context.commit('setClient', data)
  },
  async loadModules(context, serialNumber) {
    if (!serialNumber) {
      context.commit('setSystem', null)
      context.commit('setContract', null)
      return
    }
    const modules = await useFetch(`/systems/${serialNumber}/modules`)
    context.commit('setModules', modules.data)
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
