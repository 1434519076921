import { useFetch } from '../../composable/useFetch.js';

const state = () => ({
  plants: null,
  currentPlant: {}
})

const mutations = {
  setPlants(state, plants) {
    state.plants = plants
  },
  setCurrentPlant(state, plant) {
    state.currentPlant = plant
  },
  resetPlantValues(state) {
    state.currentPlant = {}
  }
}

const actions = {
  async fetchPlants({ commit }) {
    const response = await useFetch('/plants')
    commit('setPlants', response.data)
  },
  async fetchCurrentPlant({ commit }, id) {
    const response = await useFetch(`/plants/${id}`)
    commit('setCurrentPlant', response)
  },
  async updateCurrentPlant({ commit }, { id, body }) {
    const response = await useFetch(`/plants/${id}`, 'PATCH', body)
    commit('setCurrentPlant', response)
  },
  async deletePlant(context, id) {
    await useFetch(`/plants/${id}`, 'DELETE')
  },
  resetPlantValues({ commit }) {
    commit('resetPlantValues')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
