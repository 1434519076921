<template>
  <nav class="px-2 space-y-1">
    <component
      v-for="item in filteredNavigation"
      :is="item.isDisabled ? 'span' : 'router-link'"
      :key="item.name"
      :to="item.href"
      class="group flex items-center px-2 py-2 font-medium rounded-md"
      exact-active-class="bg-white !text-custom-green"
      :class="{ 'pointer-events-none select-none': item.isDisabled }"
    >
      <component
        :is="item.icon"
        class="mr-3 flex-shrink-0 h-6 w-6 text-custom-green-light"
        aria-hidden="true"
      />
      {{ item.name }}
    </component>
    <button
      @click="logout"
      class="!mt-8 group flex items-center px-2 py-2 font-medium rounded-md w-full"
    >
      <LogoutIcon
        class="mr-3 flex-shrink-0 h-6 w-6 text-custom-green-light"
        aria-hidden="true"
      />
      Logout
    </button>
  </nav>
</template>

<script>
import LogoutIcon from '../../../images/logout.svg';
import {
  CogIcon,
  CubeTransparentIcon,
  FolderIcon,
  HomeIcon,
  QuestionMarkCircleIcon,
  TruckIcon,
  UserGroupIcon
} from '@heroicons/vue/outline';
import { defineComponent, reactive, watch } from 'vue';
import axios from 'axios';
import { computed } from 'vue';
import { useFetch } from '../../composable/useFetch.js';

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { name: 'Clients', href: '/clients', icon: UserGroupIcon },
  { name: 'Systems', href: '/systems', icon: CubeTransparentIcon },
  { name: 'Admins', href: '/admins', icon: TruckIcon },
  { name: 'Plants catalogue', href: '/plants', icon: FolderIcon },
];

export default defineComponent({
  name: 'SidebarNavigation',
  components: { LogoutIcon },
  setup() {
    const state = reactive({
      currentUserRole: null,
    });

    const fetchCurrentUserRole = async () => {
      try {
        const response = await axios.get('/api/v1/current_user_role');
        state.currentUserRole = response.data.current_user_role;
      } catch (error) {
        console.error(error);
      }
    };

    watch(() => state.currentUserRole, (newValue) => {
      console.log('New current user role:', newValue);
    });

    fetchCurrentUserRole();

    const filteredNavigation = computed(() => {
      if (state.currentUserRole === 'client_admin') {
        return navigation.filter((item) => item.name === 'Systems' || item.name === 'Plants catalogue')
      } else if (state.currentUserRole === 'admin') {
        return navigation.filter((item) => item.name === 'Systems' || item.name === 'Plants catalogue')
      } else {
        return navigation
      }
    })


    const logout = async () => {
      await useFetch('/users/logout', 'DELETE', '', '')
      window.location.href = '/users/login'
    }

    return {
      navigation,
      logout,
      currentUserRole: state.currentUserRole,
      filteredNavigation,
    };
  },
});
</script>
